.page404{
//   height: calc(100vh - 130px);
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
//   background-image: url(/assets/404_dektop.jpeg);
  background-size: cover;

  .info{
    text-align: center;
    background: var(--secondary-color);
    padding: 5rem;
    border-radius: 1rem;
    // opacity: 0.7;
     h1{
        color: var(--yellow-color);
       font-size:2rem;
       margin-bottom: 2rem;
     }
     p{
      font-size:1.5rem;
      margin-bottom: 1rem;
      color: var(--white);
     }
     button {
      border-radius: 50px;
      padding: 8px 24px;
      font-size: 1.5rem;
      cursor: pointer;
      background: var(--yellow-color);;
    //   color: var(--yellow-color);
      border: none;
    //   box-shadow: 0 4px 8px 0 #ccc;
      margin-top: 1rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .page404{
    // background-image: url(/assets/404_mobile.jpeg);
  
    .info{
      margin: 2rem;
      h1{
        font-size:1.5rem;
      }
      p{
       font-size:1.2rem;
      }
      button{
        font-size:1.2rem;
      }
    }    
  }
}
